<template>
  <div :class="className" :style="styleChip">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from "vue"

export enum ContextType {
  UNSAFE = "unsafe",
  SAFE = "safe",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  LIGHT = "light",
  DARK = "dark",
  WARNING = "warning",
  SUCCESS = "success",
  INFO = "info",
  DANGER = "danger"
}
enum Aspect {
  FLAT = "flat",
  FILLED = "filled",
  OUTLINED = "outlined"
}
enum GlobalSize {
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
  XLARGE = "xl"
}
export default defineComponent({
  props: {
    type: {
      type: String as PropType<ContextType>,
      required: false,
      default: ContextType.SAFE
    },
    size: {
      type: String as PropType<GlobalSize>,
      required: false,
      default: GlobalSize.MEDIUM
    },
    aspect: {
      type: String as PropType<Aspect>,
      required: false,
      default: Aspect.FLAT
    },
    color: {
      type: String,
      required: false,
      default: null
    }
  },
  setup(props) {
    const className = computed(() => [
      "chip",
      props.type && `chip--${props.type}`,
      props.size && `chip--${props.size}`,
      props.aspect && `chip--${props.aspect}`
    ])
    const styleChip = computed(() => ({
      backgroundColor: props.color
    }))
    return { className, styleChip }
  }
})
</script>
<style lang="scss" scoped>
$mapStateColor: (
  "primary": "primary",
  "info": "info",
  "warning": "warning",
  "success": "success",
  "unsafe": "error",
  "danger": "error"
);

.chip {
  display: inline-block;
  padding: calc($dr-margin / 2) $dr-margin;
  border-radius: calc($dr-margin / 2);
  &--sm {
    font-size: $dr-font-size-caption;
  }
  &--md {
    font-size: $dr-font-size-body;
  }
  &--filled {
    &.chip {
      @each $state, $stateColor in $mapStateColor {
        &--#{$state} {
          background-color: var(--theme-color-#{$stateColor});

          color: $dr-gauze-grey-light;
        }
      }
      &:hover {
        opacity: $dr-opacity-light;
      }
    }
  }

  &--flat {
    &.chip {
      @each $state, $stateColor in $mapStateColor {
        &--#{$state} {
          color: var(--theme-color-#{$stateColor});
        }
      }
    }
  }
}
</style>
